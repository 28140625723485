<template>
    <div id="header" class="header-block" role="banner">
        <div class="header-left">
            <img
                class="header-logo"
                src="@/assets/lincs-icon.png"
                alt="LINCS logo"
            />
            <h1 class="header-title">
                {{ $t("SidePanelHeader.context_explorer") }}
            </h1>
        </div>
        <div class="header-right">
            <div class="info-tag">
                <a
                    class="info-link"
                    href="https://lincsproject.ca/docs/tools/context-explorer/"
                    target="_blank"
                    title="Documentation"
                >
                    <img
                        id="documentation"
                        src="@/assets/documentation.svg"
                        alt="Link to Documentation"
                    />
                </a>
            </div>
            <button
                class="exit-extension"
                v-on:click="exitExtension"
                aria-label="Exit Context Explorer"
                title="Exit Context Explorer"
            >
                <img
                    src="@/assets/exitExtension.svg"
                    alt="Exit extension"
                    style="width: 100%; height: 100%"
                />
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";

@Options({
    emits: ["update"],
})
export default class SidePanelHeader extends Vue {
    // Closes the sidebar and all the process happening with it
    exitExtension() {
        window.parent.postMessage({ type: "exit" }, "*");
    }
}
</script>
<style>
::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: #bbbbbb;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #127286;
}

a {
    color: #107386;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

hr {
    color: lightgrey;
    width: 100%;
}

.header-block {
    height: 60px;
    display: flex;
    border-bottom: 1px solid #ddd;
    align-items: center;
    padding: 0 10px;
    position: relative;
    width: auto;
    justify-content: space-between;
}

.header-left {
    position: relative;
    display: flex;
    align-items: center;
}

.header-left .header-title {
    font-size: 18px;
    color: #333333;
    font-weight: 400;
    margin: 0;
}

.header-left .header-logo {
    width: 35px;
    height: 35px;
    -o-object-fit: contain;
    object-fit: contain;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #ddd;
    margin-right: 10px;
}

.header-right {
    position: relative;
    display: flex;
    align-items: center;
}

.info-tag {
    display: flex;
    padding-right: 10px;
    margin-right: 10px;
    border-right: 1px solid #ddd;
    position: relative;
}

.info-link {
    display: flex;
}

.exit-extension {
    cursor: pointer;
    appearance: none;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
}
</style>
