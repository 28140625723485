<template>
    <div id="timeline-wrapper" :style="{
        height: timelineHeight,
    }">
        <hr />
        <div id="timeline-header">
            <a tabindex="0" v-on:click="previousEvent" v-on:keydown.enter="previousEvent"
                ><img
                    src="@/assets/arrow-left.svg"
                    alt="Left arrow"
                    class="timeline-arrow"
            /></a>
            <div id="timeline-header-writing">
                <h3 class="page-title">
                    {{ $t("ThirdLevelTimeline.viewing") }} ({{
                        currentEventIndex + 1
                    }}/{{ numEvents }})
                </h3>
                <h4 class="date-text">{{ currentEventDate }}</h4>
            </div>
            <a tabindex="0" v-on:click="nextEvent" v-on:keydown.enter="nextEvent"
                ><img
                    src="@/assets/arrow-right.svg"
                    alt="Right arrow"
                    class="timeline-arrow"
            /></a>
        </div>
        <hr />
        <div v-show="dateExists" id="timeline"><canvas id="timeline-canvas" aria-label="Timeline of dates related to this entity" role="img"></canvas></div>
    </div>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";
import Chart from "chart.js/auto";
@Options({
    props: {
        eventList: Object,
        currentEvent: Object,
    },
    emits: ["update"],
})
export default class ThirdLevelTimeline extends Vue {
    eventList!: any;
    currentEvent!: any;
    linkedCurrentEvent: any;
    timelineData: any;
    chart: any;
    dateExists = false;

    get currentEventIndex() {
        let index;
        for (const [i, event] of this.eventList.entries()) {
            if (event.resource == this.currentEvent.resource) {
                this.linkedCurrentEvent = event;
                index = i;
                break;
            }
        }
        return index;
    }

    get currentEventDate() {
        let date = "Undated";
        if (
            this.linkedCurrentEvent != null &&
            this.linkedCurrentEvent.startDate != null
        ) {
            date = this.linkedCurrentEvent.startDate.split("T")[0];
        }
        return date;
    }

    get numEvents() {
        return this.eventList.length;
    }

    get timelineHeight() {
        return this.dateExists ? '142px' : '55px';
    }

    mounted() {
        this.timelineData = [];
        const years: Array<number> = [];
        const defaultColor = "#BAD8DE";
        const activeColor = "#083943";
        for (const [i, event] of this.eventList.entries()) {
            if (event.startDate != null) {
                const year = parseInt(event.startDate.split("-")[0]);
                if (!years.includes(year)) {
                    this.timelineData.push({
                        year: year,
                        count: 1,
                        color:
                            this.currentEventDate != "Undated" &&
                            parseInt(this.currentEventDate.split("-")[0]) ==
                                year
                                ? activeColor
                                : defaultColor,
                        eventListIndex: i,
                    });
                    years.push(year);
                } else {
                    const yearObj = this.timelineData.find((findEvent: any) => {
                        return findEvent.year == year;
                    });

                    (yearObj as any).count += 1;
                }
            }
        }

        if (years.length != 0) {
            this.dateExists = true;

            const min = Math.min(...years);
            for (let i = min - 1; i >= min - 5; i--) {
                this.timelineData.unshift({
                    year: i,
                    count: 0,
                    color: defaultColor,
                    eventListIndex: -1,
                });
            }

            const max = Math.max(...years);
            for (let i = max + 1; i <= max + 5; i++) {
                this.timelineData.push({
                    year: i,
                    count: 0,
                    eventListIndex: -1,
                });
            }

            this.timelineData.sort((a: any, b: any) => {
                return a.year - b.year;
            });

            const canvas = (document.getElementById(
                "timeline-canvas",
            ) as HTMLCanvasElement)!;
            this.chart = new Chart(canvas, {
                type: "bar",
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: false,
                        },
                    },
                    scales: {
                        y: {
                            display: false,
                        },
                        x: {
                            display: true,
                        },
                    },
                },
                data: {
                    labels: this.timelineData.map((row: any) => row.year),
                    datasets: [
                        {
                            label: "Related Events",
                            backgroundColor: this.timelineData.map(
                                (row: any) => row.color,
                            ),
                            data: this.timelineData.map((row: any) => row.count),
                        },
                    ],
                },
            });
            canvas.addEventListener("click", this.handleTimelineClick, false);
        } else {
            this.$emit("update", { timelinePartial: true });
        }
    }

    handleTimelineClick(event: any) {
        const selection = this.chart.getElementsAtEventForMode(
            event,
            "nearest",
            { intersect: true },
            true,
        );

        if (selection.length != 0) {
            const nextIndex =
                this.timelineData[selection[0].index].eventListIndex;

            this.chart.destroy();
            this.$emit("update");
            window.parent.postMessage(
                {
                    type: "remove-and-add-entity-to-graph",
                    label:
                        this.eventList[nextIndex].resourceLabel ??
                        this.eventList[nextIndex].resource,
                    uri: this.eventList[nextIndex].resource,
                },
                "*",
            );
        }
    }

    nextEvent() {
        const nextIndex = this.currentEventIndex + 1;
        if (nextIndex < this.numEvents) {
            this.$emit("update");
            window.parent.postMessage(
                {
                    type: "remove-and-add-entity-to-graph",
                    label:
                        this.eventList[nextIndex].resourceLabel ??
                        this.eventList[nextIndex].resource,
                    uri: this.eventList[nextIndex].resource,
                },
                "*",
            );
        }
    }

    previousEvent() {
        const previousIndex = this.currentEventIndex - 1;
        if (previousIndex >= 0) {
            this.$emit("update");
            window.parent.postMessage(
                {
                    type: "remove-and-add-entity-to-graph",
                    label:
                        this.eventList[previousIndex].resourceLabel ??
                        this.eventList[previousIndex].resource,
                    uri: this.eventList[previousIndex].resource,
                },
                "*",
            );
        }
    }
}
</script>
<style>
/* Third Level Timeline Start */

#timeline-wrapper {
    height: 142px;
    width: 100%;
    overflow: hidden;
    position: fixed;
    bottom: 0px;
    background-color: white;
    z-index: 9999;
}

#timeline-wrapper hr {
    margin: 0;
}

#timeline-header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 5px;
    padding-bottom: 5px;
}

#timeline-header-writing {
    display: flex;
    flex-direction: column;
    margin-left: 90px;
    margin-right: 90px;
}

#timeline {
    height: 90px;
    width: 349px;
    display: inline-block;
    position: relative;
}

.page-title {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
}

.date-text {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
}

.timeline-arrow {
    cursor: pointer;
}

/* Third Level Timeline End */
</style>
