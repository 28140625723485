<template>
    <div
        class="annotation-card-container"
        tabindex="0"
        v-on:click="selectAnnotation"
        v-on:keydown.enter="selectAnnotation"
    >
        <div class="annotation-info">
            <div class="annotation-title">
                <div class="annotation-img-container" v-if="imageSrc != ''">
                    <img class="annotation-img" :src="imageSrc" />
                </div>
                <div style="flex: 1">
                    <h1>{{ label }}</h1>
                </div>
            </div>
            <p v-if="target != ''" class="truncate-text">{{
                target
            }}</p>
            <p v-if="dataset != ''" class="truncate-text">{{ dataset }}</p>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";

@Options({
    props: {
        label: String,
        target: String,
        dataset: String,
        index: Number,
    },
})
export default class AnnotationCard extends Vue {
    label!: string;
    target!: string;
    dataset!: string;
    index!: number;
    imageSrc = "";

    selectAnnotation() {
        if (this.target != "") {
            fetch("/get-icon?target=" + encodeURIComponent(this.target), {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        console.log(["QUERY-ERROR", response]);
                    }
                })
                .then((response) => {
                    const url = new URL(this.target);
                    if (response != null) {
                        this.imageSrc =
                            url.protocol + "//" + url.hostname + response.url;

                        this.$emit("change", { image: this.imageSrc });
                    }
                });
        }

        this.$emit('update', { index: this.index })
    }
}
</script>
<style>
.annotation-card-container {
    cursor: pointer;
    transition: 0.3s all ease;
}
.annotation-card-container:hover,
.annotation-card-container.active {
    background-color: #edf6f8;
    transition: 0.3s all ease;
    border-color: #edf6f8;
}
.annotation-info {
    padding: 8px 12px;
}
.annotation-info h1 {
    color: #333333;
    font-size: 16px;
    font-weight: 500;
    margin: 0px;
    padding-bottom: 2px;
}
.annotation-info p,
.annotation-info a {
    display: block;
    color: #5e6364;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 0px;
    width: 100%;
    overflow: hidden;
}
.annotation-title {
    display: flex;
    justify-content: center;
    align-items: center;
}
.annotation-img-container {
    overflow: hidden;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 5px;
}
.annotation-img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
</style>
