<template>
    <div
        class="site-section"
        :class="{
            'third-level-timeline': isLastEntity && !isAnnotationView && timelineState == 'full',
            'third-level-partial-timeline': isLastEntity && !isAnnotationView && timelineState == 'partial',
            'third-level-no-timeline': isLastEntity && !isAnnotationView && timelineState == 'none',
            'annotation-view-space': isAnnotationView,
        }"
        :role="(isLastEntity || isAnnotationView)  ? undefined : 'main'"
    >
        <div
            class="header-btn-block"
            v-on:click="$emit('update', {})"
            v-if="!hideBackButton"
            v-show="!isLastEntity"
        >
            <a href="javascript:void(0)" class="back-btn">
                <img src="@/assets/arrow-left.svg" alt="Back Button" />
                {{ $t("ViewMoreDetailsView.back") }}
            </a>
        </div>
        <div class="entity-details" v-show="!isLastEntity">
            <div class="entity-main-img">
                <!--Image-->
                <img
                    class="img-fluid"
                    :src="
                        data.mainEntityInfo.image ||
                        'https://www.slntechnologies.com/wp-content/uploads/2017/08/ef3-placeholder-image.jpg'
                    "
                    :alt="
                        (data.mainEntityInfo.typeLabel ?? data.mainEntityInfo.type)  + ' icon'
                    "
                    v-if="
                        !(
                            data.mainEntityInfo.type ==
                                'http://www.cidoc-crm.org/cidoc-crm/E53_Place' &&
                            mainEntityPlaceCoords != null
                        )
                    "
                />
                <!--Wikidata Attribution if needed-->
                <a
                    v-if="
                        data.mainEntityInfo.hasOwnProperty(
                            'imageWikidataAttribution',
                        )
                    "
                    class="wikidata-attribution"
                    :href="data.mainEntityInfo.imageWikidataAttribution"
                    target="_blank"
                    :title="data.mainEntityInfo.imageName"
                >
                    <img
                        class="attribution-image"
                        src="@/assets/wikidata.png"
                    />
                    Wikidata
                </a>
                <!--Map if this is a place-->
                <div
                    v-if="
                        data.mainEntityInfo.type ==
                            'http://www.cidoc-crm.org/cidoc-crm/E53_Place' &&
                        mainEntityPlaceCoords != null
                    "
                    style="height: 250px; width: 100%"
                >
                    <l-map
                        ref="map"
                        v-model:zoom="zoom"
                        :center="mainEntityPlaceCoords"
                    >
                        <l-tile-layer
                            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
                            layer-type="base"
                            attribution="Tiles &copy; Esri"
                        ></l-tile-layer>
                        <l-marker :lat-lng="mainEntityPlaceCoords"></l-marker>
                    </l-map>
                </div>
            </div>
            <div class="entity-main-title">
                <h2
                    :title="
                        data.mainEntityInfo.resourceLabel ??
                        data.mainEntityInfo.resource
                    "
                >
                    {{
                        data.mainEntityInfo.resourceLabel ??
                        data.mainEntityInfo.resource
                    }}
                </h2>
                <h4 :title="data.mainEntityInfo.typeLabel">
                    {{ data.mainEntityInfo.typeLabel }}
                </h4>
            </div>
            <div class="entity-source-list">
                <h3>{{ $t("ViewMoreDetailsView.sources") }}</h3>
                <div class="source-list"
                    v-for="(dataset, datasetIcon) in datasets"
                    :key="datasetIcon">
                    <span
                        class="attribution-container"
                    >
                        <span class="legend-icon">{{ dataset.legendIcon }}</span>
                        <span style="padding-right: 26px;"></span>
                    </span>
                    <span
                        class="predicate-text"
                        :title="dataset.name ?? dataset.uri"
                    >
                        {{ dataset.name ?? dataset.uri }}
                    </span>
                </div>
            </div>
        </div>
        <ul class="search-info-section">
            <li v-if="!isLastEntity">
                <h3 class="view-more-properties">
                    {{ $t("ViewMoreDetailsView.properties") }}
                </h3>
            </li>
            <li class="property-group" v-if="isLastEntity">
                <h4 class="info-title wrap-text">
                    {{ $t("ViewMoreDetailsView.sources") }}
                </h4>
                <div
                    v-for="(dataset, datasetIcon) in datasets"
                    :key="datasetIcon"
                >
                    <div class="details-box">
                        <span
                            class="attribution-container"
                        >
                            <span class="legend-icon">{{ dataset.legendIcon }}</span>
                            <span style="padding-right: 26px;"></span>
                        </span>
                        <span
                            class="predicate-text"
                            :title="dataset.name"
                        >
                            {{ dataset.name }}
                        </span>
                    </div>
                </div>
            </li>
            <li class="property-group">
                <h4 class="info-title wrap-text">
                    {{ $t("ViewMoreDetailsView.type") }}
                </h4>
                <div class="details-box">
                    {{ data.mainEntityInfo.typeLabel }}
                </div>
            </li>
            <li
                v-for="(predicate, predicateIndex) in dataPredicates"
                :key="predicateIndex"
            >
                <div v-if="!predicate.objectIsURI" class="property-group">
                    <h4
                        class="info-title wrap-text"
                        :title="predicate.predicate"
                    >
                        {{ predicate.predicate }}
                    </h4>
                    <div
                        class="data-property"
                        v-for="(outgoingPred, entityIndex) in data
                            .mainEntityInfo.outgoing"
                        :key="entityIndex"
                    >
                        <div
                            class="details-box attribution-container"
                            v-if="
                                (outgoingPred.predicateLabel ??
                                    outgoingPred.predicate) ==
                                predicate.predicate
                            "
                        >
                            <a
                                v-if="
                                    predicate.hasOwnProperty(
                                        'wikidataAttribution',
                                    )
                                "
                                class="attribution-container"
                                :href="predicate.wikidataAttribution"
                                target="_blank"
                                :title="predicate.wikidataAttribution"
                            >
                                <img
                                    class="attribution-image"
                                    src="@/assets/wikidata.png"
                                />
                            </a>
                            <span 
                                v-if="predicate.hasOwnProperty('legendIcon')" 
                                class="attribution-container"
                            >
                                <span class="legend-icon">{{ predicate.legendIcon }}</span>
                                <span style="padding-right: 26px;"></span>
                            </span>
                            <span
                                :class="{
                                    'wrap-text':
                                        predicate.predicate !== 'has note',
                                    'predicate-text': true,
                                }"
                                :title="
                                    outgoingPred.objectLabel ??
                                    outgoingPred.object
                                "
                            >
                                {{
                                    outgoingPred.objectLabel ??
                                    outgoingPred.object
                                }}
                            </span>
                        </div>
                    </div>
                </div>
            </li>
            <li
                v-for="(predicate, predicateIndex) in dataPredicates"
                :key="predicateIndex"
            >
                <div v-if="predicate.objectIsURI" class="property-group">
                    <h4
                        class="info-title wrap-text"
                        :title="predicate.predicate"
                    >
                        {{ predicate.predicate }}
                    </h4>
                    <div
                        v-for="(outgoingPred, entityIndex) in data
                            .mainEntityInfo.outgoing"
                        :key="entityIndex"
                    >
                        <div
                            class="details-box"
                            v-if="
                                (outgoingPred.predicateLabel ??
                                    outgoingPred.predicate) ==
                                predicate.predicate
                            "
                        >
                            <!--Array Display-->
                            <div v-if="Array.isArray(outgoingPred.object)">
                                <div
                                    v-for="(
                                        predArrayItem, predArrayIndex
                                    ) in outgoingPred.object"
                                    :key="predArrayIndex"
                                    class="details-box"
                                >
                                    <a
                                        class="wrap-text"
                                        :href="
                                            'https://rs-review.lincsproject.ca/resource/?uri=' +
                                            predArrayItem.uri
                                        "
                                        target="_blank"
                                        :title="
                                            predArrayItem.label ??
                                            predArrayItem.uri
                                        "
                                    >
                                        {{
                                            predArrayItem.label ??
                                            predArrayItem.uri
                                        }}
                                        <img
                                            src="@/assets/external-link-blue.svg"
                                        />
                                    </a>
                                </div>
                            </div>

                            <!--Person Display-->
                            <PersonDetailsCard
                                :person="outgoingPred"
                                v-else-if="
                                    outgoingPred.type ==
                                    'http://www.cidoc-crm.org/cidoc-crm/E21_Person'
                                "
                            />

                            <!--Annotation Target Display-->
                            <AnnotationTargetCard
                                :target="outgoingPred.object"
                                :image="outgoingPred.image"
                                :title="
                                    outgoingPred.objectLabel ??
                                    outgoingPred.object
                                "
                                v-else-if="outgoingPred.nonRsURI"
                            />

                            <!--Normal Display-->
                            <div v-else>
                                <span 
                                    v-if="predicate.hasOwnProperty('legendIcon')" 
                                    class="attribution-container"
                                >
                                    <span class="legend-icon">{{ predicate.legendIcon }}</span>
                                    <span style="padding-right: 26px;"></span>
                                </span>
                                <a
                                    class="wrap-text predicate-text"
                                    :href="
                                        'https://rs-review.lincsproject.ca/resource/?uri=' +
                                        outgoingPred.object
                                    "
                                    target="_blank"
                                    :title="
                                        outgoingPred.objectLabel ??
                                        outgoingPred.object
                                    "
                                >
                                    {{
                                        outgoingPred.objectLabel ??
                                        outgoingPred.object
                                    }}
                                    <img src="@/assets/external-link-blue.svg" />
                                </a>
                            </div>

                            <!--Map Display-->
                            <div
                                v-if="
                                    Object.getOwnPropertyDescriptor(
                                        outgoingPred,
                                        'coords',
                                    )
                                "
                                style="height: 200px; width: 100%"
                            >
                                <l-map
                                    :ref="'map-' + entityIndex"
                                    v-model:zoom="outgoingPred.zoom"
                                    :center="outgoingPred.coords"
                                    :id="entityIndex"
                                >
                                    <l-tile-layer
                                        url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
                                        layer-type="base"
                                        attribution="Tiles &copy; Esri"
                                    ></l-tile-layer>
                                    <l-marker
                                        :lat-lng="outgoingPred.coords"
                                    ></l-marker>
                                </l-map>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li class="property-group">
                <h4 class="info-title wrap-text">
                    {{ $t("ViewMoreDetailsView.identifier") }}
                </h4>
                <ResearchSpaceButton
                    :resource="data.mainEntityInfo.resource"
                ></ResearchSpaceButton>
            </li>
        </ul>
    </div>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";
import "leaflet/dist/leaflet.css";
import { LMap, LMarker, LTileLayer } from "@vue-leaflet/vue-leaflet";
import PersonDetailsCard from "./PersonDetailsCard.vue";
import ResearchSpaceButton from "./ResearchSpaceButton.vue";
import AnnotationTargetCard from "./AnnotationTargetCard.vue";
@Options({
    props: {
        data: Object,
        hideBackButton: {
            type: Boolean,
            default: false,
        },
        isLastEntity: {
            type: Boolean,
            default: false,
        },
        timelineState: {
            type: String,
            default: 'none',
        },
        isAnnotationView: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["update"],
    components: {
        LMap,
        LMarker,
        LTileLayer,
        PersonDetailsCard,
        ResearchSpaceButton,
        AnnotationTargetCard,
    },
})
export default class ViewMoreDetailsView extends Vue {
    data!: any;
    hideBackButton!: boolean;
    isLastEntity!: boolean;
    timelineState!: string;
    isAnnotationView!: boolean;
    zoom = 8;
    datasets: any = [];

    // This is the url for filling out the dataset labels
    apiURL = 
        "https://lincs-api.lincsproject.ca/api/dataset";

    // This is the authToken needed for the lincs-api's rate limiting
    authToken = 
        "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJVcG5aOHZaTGNUemhTMWtzdGN2NDVkR205QXYwUmlTbElaQ0YzZEhxR1JRIn0.eyJleHAiOjE3NDIzMjMyMjcsImlhdCI6MTcxMDc4NzIyNywianRpIjoiMjU2OTU1YjUtOTkzNi00OGZmLThjMmMtYjdkOWRiMTliZjc0IiwiaXNzIjoiaHR0cHM6Ly9rZXljbG9hay5saW5jc3Byb2plY3QuY2EvcmVhbG1zL2xpbmNzIiwiYXVkIjpbInZlcnNkLXVpIiwicmVzZWFyY2hzcGFjZSIsImFkbWluLWNsaSIsImJyb2tlciIsImFjY291bnQiXSwic3ViIjoiYmQ5MTRkMTEtZmVkMi00ODllLWI0MGQtNDk0YjkzZDFmOGU4IiwidHlwIjoiQmVhcmVyIiwiYXpwIjoibGluY3MtYXBpIiwiYWNyIjoiMSIsImFsbG93ZWQtb3JpZ2lucyI6WyIvKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsiVXNlciIsImRlZmF1bHQtcm9sZXMtbGluY3MiLCJvZmZsaW5lX2FjY2VzcyIsInVtYV9hdXRob3JpemF0aW9uIl19LCJyZXNvdXJjZV9hY2Nlc3MiOnsidmVyc2QtdWkiOnsicm9sZXMiOlsidXNlciJdfSwicmVzZWFyY2hzcGFjZSI6eyJyb2xlcyI6WyJjb250cmlidXRvciIsImd1ZXN0Il19LCJhZG1pbi1jbGkiOnsicm9sZXMiOlsiYWRtaW4iXX0sImJyb2tlciI6eyJyb2xlcyI6WyJyZWFkLXRva2VuIl19LCJhY2NvdW50Ijp7InJvbGVzIjpbIm1hbmFnZS1hY2NvdW50IiwibWFuYWdlLWFjY291bnQtbGlua3MiLCJ2aWV3LXByb2ZpbGUiXX19LCJzY29wZSI6ImVtYWlsIHByb2ZpbGUgYXZhdGFyX3VybCIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiY2xpZW50SG9zdCI6IjcwLjc1LjExMi4xMSIsInByZWZlcnJlZF91c2VybmFtZSI6InNlcnZpY2UtYWNjb3VudC1saW5jcy1hcGkiLCJjbGllbnRBZGRyZXNzIjoiNzAuNzUuMTEyLjExIiwiY2xpZW50X2lkIjoibGluY3MtYXBpIn0.ivjIKG0hWe5ULAseATO4RYUmR8GCEZaTnbE70zoT-xdNxz-jXZcGmTLnGf_t34X3BEX13u-unCPGv7T5M5Wnn6f_ohG5WW8H18oSxiAflSLue3TEpgEODACXS2rzP8gDODof2cC1MSDL9E_YI4lpQ6XAl8aMb-ojhzjZ4RJUbjXq3epQ-rmvg2R5DYtKSPtn8f1o2zooGVZg8lO_Ymi9tAxqr7PsayMXExsD2R_SShrUD9DS-1Vfvrh8a1dxdquUtZ1GHc90Ms08BxdV8hBe0Q8UvQorZgQ6dOUnKUId6250qcENqXjvbttN--FPt4z8BZDphaA38Itm-q0UV4YesQ";

    // Sort all the duplicate predicates into a common array and their respective data
    get dataPredicates() {
        function onlyUnique(value: any, index: any, array: any) {
            const map = array.map((pred: any) => pred.predicate);
            return map.indexOf(value.predicate) === index;
        }

        const allPreds = this.data.mainEntityInfo.outgoing.map(
            (pred: any) => ({
                predicate: pred.predicateLabel ?? pred.predicate,
                objectIsURI: pred.objectIsURI,
                ...(Object.prototype.hasOwnProperty.call(
                    pred,
                    "wikidataAttribution",
                ) && {
                    wikidataAttribution: pred.wikidataAttribution,
                }),
                ...(Object.prototype.hasOwnProperty.call(
                    pred,
                    "graph",
                ) && {
                    legendIcon: this.getLegendIcon(pred.graph),
                }),
            }),
        );

        return allPreds.filter(onlyUnique);
    }

    // Fetch co-ordinates for the main entity if available
    get mainEntityPlaceCoords() {
        for (const outgoingPred of this.data.mainEntityInfo.outgoing) {
            if (
                outgoingPred.predicate ==
                "http://www.cidoc-crm.org/cidoc-crm/P168_place_is_defined_by"
            ) {
                let coords = outgoingPred.object.replace("POINT(", "");
                coords = coords.replace(")", "");
                coords = coords.split(" ");
                coords = [parseFloat(coords[1]), parseFloat(coords[0])];
                return coords;
            }
        }

        return null;
    }

    getLegendIcon(dataset: string) {
        const datasetSplit = dataset.split("/");
        const datasetName = datasetSplit[datasetSplit.length - 1];
        const datasetIcon = datasetName.slice(0, 2).toUpperCase();
        if (!this.datasets.map((dataset: any) => dataset.uri).includes(dataset)) {
            this.datasets.push({uri: dataset, legendIcon: datasetIcon});
            this.getDatasetLabel(this.datasets[this.datasets.length - 1]);
        }
        return datasetIcon;
    }

    getDatasetLabel(datasetObj: any) {
        fetch(this.apiURL, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': this.authToken,
            },
            method: "POST",
            body: JSON.stringify({
                uri: datasetObj.uri,
                language: this.$i18n.locale
            }),
        })
        .then((response) => {
            if (response.ok) {
            return response.json();
            }
            throw new Error("FAILURE");
        })
        .then((response) => {
            for (const pred of response) {
                if (pred.predicate == "http://purl.org/dc/terms/title") {
                    datasetObj.name = pred.object;
                    break;
                }
            }
        })
        .catch(() => {
            console.log("Query Error");
        });
    }
}
</script>
<style>
ul {
    list-style-type: none;
    padding-inline-start: 0px;
    margin-block-start: 0px;
    margin-block-end: 0px;
}

.entity-details {
    position: relative;
    padding: 8px 8px 10px 8px;
    margin: 16px 12px 12px 12px;
    background-color: #083943;
    color: white;
    border-radius: 5px;
}

.wikidata-attribution {
    background-color: rgba(0, 0, 0, 0.64);
    padding: 5px;
    border-radius: 15px;
    color: white;
    font-size: 14px;
    position: absolute;
    right: 5px;
    top: 5px;
    display: flex;
    gap: 2px;
}

.entity-main-img {
    position: relative;
    width: auto;
    padding-bottom: 16px;
}

.entity-main-img img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.entity-main-title {
    position: relative;
    width: auto;
}

.entity-main-title h2 {
    font-size: 20px;
    text-align: left;
    margin: 0 0 0 0;
}

.entity-main-title h4 {
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    margin: 6px 0 0 0;
    color: #CCCCCC;
}

.entity-source-list h3 {
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    margin: 6px 0 6px 0;
    color: #BAD8DE;
}

.source-list {
    margin-bottom: 6px;
    text-align: left;
}

.external-btn-links {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #083943;
    height: 35px;
    border-radius: 10px;
    text-decoration: none;
    color: #083943;
    transition: 0.3s all ease;
    background-color: #fff;
    margin-bottom: 15px;
}

.external-btn-links:hover {
    background-color: #edf6f8;
    transition: 0.3s all ease;
    color: #083943;
}

.external-btn-links img {
    margin-left: 5px;
}

.search-info-section {
    position: relative;
    padding: 0px 12px 0px;
}

.view-more-properties {
    margin: 0px 0 12px 0;
    font-size: 18px;
    color: #333;
    position: relative;
    text-align: left;
    font-weight: 600;
}

.info-title {
    text-align: left;
    font-size: 14px;
    display: block;
    margin: 0 0 8px 0;
    font-weight: 400;
    letter-spacing: 0.03em;
    color: #525455;
}

.wrap-text {
    overflow: hidden;
    overflow-wrap: anywhere;
    line-height: normal;
}

.details-box {
    text-align: left;
    position: relative;
    display: block;
    margin: 0 0 8px 0;
    color: #333;
}

.attribution-image {
    width: 25px !important;
}

.attribution-container {
    vertical-align: middle;
}

.legend-icon {
    position: absolute;
    padding: 5px;
    margin-right: 5px;
    color: white;
    background-color: #107386;
    font-size: 10px;
    border-radius: 50%;
}

.predicate-text {
    line-height: 22px;
}

.third-level-timeline {
    padding: 5px 5px;
    height: calc(100vh - 465px) !important;
}

.third-level-no-timeline {
    padding: 5px 5px;
    height: calc(100vh - 309px) !important;
}

.third-level-partial-timeline {
    padding: 5px 5px;
    height: calc(100vh - 390px) !important;
}

.annotation-view-space {
    padding: 5px 5px;
    height: calc(100vh - 323px) !important;
}

.property-group {
    margin-bottom: 16px;
}
</style>
