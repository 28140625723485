<template>
    <div
        class="entity-url-btn profile-section-collapse"
        tabindex="0"
        v-on:click="handleAddToGraph(data)"
        v-on:keydown.enter="handleAddToGraph(data)"
    >
        <div class="entity-texts-box" :class="{ 
            'entity-texts-box-short': data.hasOwnProperty('incoming'),
            'entity-texts-box-long': !data.hasOwnProperty('incoming'),
        }">
            <h4
                class="entity-label"
                :title="data.resourceLabel ?? data.objectLabel ?? data.object"
            >
                {{ data.resourceLabel ?? data.objectLabel ?? data.object }}
            </h4>
            <div class="entity-type-box">
                <span class="entity-type-text">
                    {{ data.typeLabel ?? data.type ?? "Literal" }}
                </span>
            </div>
        </div>
        <div class="entity-btns-box" v-if="data.hasOwnProperty('incoming')">
            <button
                tabindex="0"
                class="icon-btn white-bg"
                v-on:click="(event) => { expandConnectionsDropdown(event, index) }"
                v-on:keydown.enter="(event) => { expandConnectionsDropdown(event, index) }"
                aria-label="Expand Connections Dropdown"
            >
                <img
                    class="large-icon"
                    src="@/assets/document-color-icon.svg"
                />
            </button>
        </div>
    </div>

    <!--Connected Entity Expanded Details-->
    <div
        v-if="currentExpandedIndex === index"
        class="profile-section light-bg profile-section-collapse display activeEntity"
    >
        <div class="profile-details profile-properties">
            <div
                class="low-tone"
                v-for="(incomingPred, index) in data.incoming"
                :key="index"
            >
                <div class="pro-details-box">
                    <span class="card-dropdown-label">{{ $t("EntityResourceCard.connection") }}</span>
                    <p
                        :title="
                            incomingPred.resourceLabel ?? incomingPred.resource
                        "
                    >
                        {{
                            incomingPred.resourceLabel ?? incomingPred.resource
                        }}
                    </p>
                </div>
            </div>
            <div class="low-tone" v-if="data?.predicate">
                <div class="pro-details-box">
                    <span class="card-dropdown-label">{{ $t("EntityResourceCard.predicate") }}</span>
                    <p
                        class="truncate-text"
                        :title="data.predicateLabel ?? data.predicate"
                    >
                        {{ data.predicateLabel ?? data.predicate }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";
@Options({
    props: {
        data: Object,
        index: Number,
        currentExpandedIndex: Number,
    },
    emits: ["update"],
})
export default class EntityResourceCard extends Vue {
    showMore = false;
    data!: any;
    index!: number;
    currentExpandedIndex!: number;

    // Add the selected entity to the bubble
    handleAddToGraph(data: any) {
        window.parent.postMessage(
            {
                type: "add-entity-to-graph",
                label: data.resourceLabel,
                uri: data.resource,
            },
            "*",
        );
    }

    // Callback for opening the dropdown under the card
    expandConnectionsDropdown(event: any, index: number) {
        event.stopPropagation();
        this.$emit('update', { index: index, mainEntity: false });
    }
}
</script>
<style>
.entity-url-btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 12px;
    padding: 10px 15px 10px 15px;
    transition: 0.3s all ease;
    text-decoration: none;
    cursor: pointer;
}

.entity-url-btn .plus-icon {
    display: none;
}

.entity-url-btn:hover,
.entity-url-btn.active {
    background-color: #edf6f8;
    transition: 0.3s all ease;
    border-color: #edf6f8;
}

.entity-url-btn:hover .entity-label,
.entity-url-btn.active .entity-label {
    color: #333333;
}

.entity-url-btn:hover .url-text,
.entity-url-btn.active .url-text {
    color: #333333;
}

.entity-url-btn:hover .plus-icon,
.entity-url-btn.active .plus-icon {
    display: block;
}

.entity-texts-box {
    position: relative;
}

.entity-texts-box-long {
    width: 100%;
}

.entity-texts-box-short {
    width: calc(100% - 40px);
}

.entity-texts-box .entity-label {
    width: 100%;
    padding: 0;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    margin: 0 0 5px 0;
    word-wrap: break-word;
    text-overflow: unset;
    white-space: unset;
}

.entity-texts-box .url-text {
    font-size: 14px;
    color: #525455;
    margin: 0;
    font-weight: 400;
    display: inline-block;
    width: 100%;
}

.entity-type-box {
    position: relative;
    display: flex;
    align-items: center;
    margin: 10px 0 0 0;
}

.entity-type-box .entity-type-text {
    color: #414343;
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 0 0;
}

.entity-type-box .dot-icon {
    width: 7px;
    margin: 0 5px;
}

.entity-type-box label {
    color: #244d6f;
    border-radius: 20px;
    border: 1px solid #244d6f;
    padding: 1px 3px;
    display: flex;
    font-size: 14px;
    align-items: center;
}

.entity-type-box label .url-icon {
    width: 15px;
    margin: 0px 0 -2px 2px;
}

.entity-type-box label .url-icon-active {
    display: none;
}

.entity-url-btn:hover .entity-type-box .entity-type-text,
.entity-url-btn.active .entity-type-box .entity-type-text {
    color: #333333;
}

.entity-url-btn:hover .entity-type-box label,
.entity-url-btn.active .entity-type-box label {
    color: #ffffff;
    border-color: #ffffff;
}

.entity-url-btn.active .entity-type-box label .url-icon {
    display: none;
}

.entity-url-btn.active .entity-type-box label .url-icon-active {
    display: block;
}

.activeEntity {
    display: flex !important;
}

.display {
    display: none;
}

.entity-btns-box {
    position: relative;
    display: flex;
    align-items: center;
}

.entity-btns-box .icon-btn {
    margin-left: 10px;
}

.profile-section-collapse.profile-section.light-bg .low-tone {
    background-color: #ffffff;
}

.profile-section-collapse.profile-section.light-bg .low-tone:nth-child(odd) {
    background-color: #e7f1f3;
}

.card-dropdown-label {
    color: #5E6364;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
</style>
