<template>
    <div>
        <div class="entity-title entity-category-title">
            <h3 id="entity-categories">
                {{ $t("CategorySelection.filter_by_category") }}
            </h3>
        </div>
        <ul class="entity-btns-section" :aria-labelledby="'entity-categories'">
            <li v-for="(category, index) in entityCategories" :key="index">
                <a
                    href="javascript:void(0)"
                    class="entity-btn entity-category-btn"
                    v-on:click="
                        ($parent as any).displayEntitiesByCategory(category)
                    "
                >
                    {{ category }}
                    <img
                        class="arrow-right"
                        src="@/assets/arrow-right.svg"
                        alt="Right arrow"
                    />
                </a>
            </li>
        </ul>
    </div>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";

@Options({
    props: {
        entityCategories: Object,
    },
})
export default class CategorySelection extends Vue {
    entityCategories!: any;
}
</script>
<style>
.entity-btns-section {
    position: relative;
    margin: 0;
    padding: 0;
    padding-bottom: 55px;
    width: 100%;
    list-style: none;
}

.entity-btns-section li {
    border-bottom: 1px solid #f2f2f2;
}

.entity-btn {
    padding: 15px 10px 15px 0px;
    font-size: 18px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #333333;
    transition: 0.3s all ease;
}

.entity-btn:hover {
    color: #333333;
    background-color: #edf6f8;
    transition: 0.3s all ease;
    text-decoration: none;
}

.entity-btn.entity-category-btn {
    padding: 15px;
}
</style>
